<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    ref="drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    clipped
    :mini-variant.sync="mini"
    mini-variant-width="60"
    width="260"
    v-bind="$attrs"
  >
    <!-- <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template> -->
    <v-list-item dense>
      <v-list-item-content>
        <div :class="{ 'display-1': true, 'text-truncate': mini, 'black--text': true, 'text-center': true }">
          {{ biblioteca }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-1" />
    <v-list
      ref="listItems"
      :class="{ 'pl-2': !mini, 'pr-1': !mini, 'mr-2': !mini, 'pl-3': mini, 'pr-3': mini }"
      expand
      nav
    >
      <div></div>
      <template v-for="(item, i) in menuItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item" :mini="mini" catalog> </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" :text="item.text" :mini="mini" catalog />
      </template>
      <div></div>
      <!-- <div
        class="fb-page"
        data-href="https://www.facebook.com/ebibliophil/"
        data-tabs=""
        data-width="250"
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote cite="https://www.facebook.com/ebibliophil/" class="fb-xfbml-parse-ignore">
          <a href="https://www.facebook.com/ebibliophil/">EBiblioPhil</a>
        </blockquote>
      </div> -->
      <!-- <div
        class="fb-page"
        data-href="https://www.facebook.com/ebibliophil/"
        data-width="298"
        data-hide-cover="false"
        data-show-facepile="false"
        data-show-posts="false"
      ></div> -->
      <div>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Febibliophil%2F&tabs&width=250&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=398371290300999"
          width="240"
          height="130"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
      <v-list-item v-for="(library, ind) in $config.cats" :key="ind" dense>
        <!-- <v-list-item-content> -->
        <a
          :class="{
            'v-breadcrumbs__item': true,
            'body-2': true,
            'text-truncate': mini
          }"
          :href="'/' + (library.link ? library.link + '/' : '')"
        >
          {{ library.name }}
        </a>
        <!-- <div :class="{ 'body-2': true, 'text-truncate': mini, 'black--text': true, 'text-center': true }">
            {{ library.library.public_name }}
          </div> -->
        <!-- </v-list-item-content> -->
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-divider class="mb-1" />
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title>
            <span class="text-caption">v5.0</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <span class="text-caption">{{ libraryCode }}</span>
              </div>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in $config.cats" :key="index">
                <v-list-item-title>
                  <a class="v-breadcrumbs__item" :href="'/' + item.link">{{ item.name }}</a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex"
import axios from "../../../../plugins/axios"
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    monItems: [],
    mini: null,
    libraries: [],
    items: [
      {
        icon: "mdi-book-outline",
        title: "Monografii",
        to: "/mon",
        prefix: "mon",
        ctg: ""
      },
      {
        text: true,
        title: "Carte",
        to: "/mon/cat-monography",
        prefix: "mon",
        ctg: "monography"
      },
      {
        text: true,
        title: "Seriale",
        to: "/mon/cat-serials",
        prefix: "mon",
        ctg: "serials"
      },
      {
        text: true,
        title: "Audio",
        to: "/mon/cat-audio",
        prefix: "mon",
        ctg: "audio"
      },
      {
        text: true,
        title: "Video",
        to: "/mon/cat-video",
        prefix: "mon",
        ctg: "video"
      },
      {
        text: true,
        title: "Res. electronică",
        to: "/mon/cat-electronic-resource",
        prefix: "mon",
        ctg: "electronic-resource"
      },
      {
        text: true,
        title: "Seriale vechi",
        to: "/mon/cat-old-serials",
        prefix: "mon",
        ctg: "old-serials"
      },
      {
        text: true,
        title: "Microformate",
        to: "/mon/cat-microformats",
        prefix: "mon",
        ctg: "microformats"
      },
      {
        text: true,
        title: "Grafice",
        to: "/mon/cat-graphic",
        prefix: "mon",
        ctg: "graphic"
      },
      {
        text: true,
        title: "Componente",
        to: "/mon/cat-components",
        prefix: "mon",
        ctg: "components"
      },
      {
        text: true,
        title: "Cartografice",
        to: "/mon/cat-cartographic",
        prefix: "mon",
        ctg: "cartographic"
      },
      {
        text: true,
        title: "Artefacte",
        to: "/mon/cat-artifacts",
        prefix: "mon",
        ctg: "artifacts"
      },
      {
        text: true,
        title: "Mon. veche",
        to: "/mon/cat-old-monography",
        prefix: "mon",
        ctg: "old-monography"
      },
      {
        icon: "mdi-book-open-blank-variant",
        title: "Articole",
        to: "/art",
        prefix: "art",
        ctg: ""
      },
      {
        text: true,
        title: "Articole",
        to: "/art/cat-articol",
        prefix: "art",
        ctg: "articol"
      },
      {
        text: true,
        title: "Articole proprii",
        to: "/art/cat-articol-publicatie-proprie",
        prefix: "art",
        ctg: "articol-publicatie-proprie"
      },
      {
        icon: "mdi-bank",
        title: "Autorități",
        to: "/ath",
        prefix: "ath",
        ctg: ""
      },
      {
        text: true,
        title: "Nume personal",
        to: "/ath/cat-nume-personal",
        prefix: "ath",
        ctg: "nume-personal"
      },
      {
        text: true,
        title: "Corporaţie",
        to: "/ath/cat-nume-corporatie",
        prefix: "ath",
        ctg: "nume-corporatie"
      },
      {
        text: true,
        title: "Nume terit./geogr.",
        to: "/ath/cat-nume-teritorial-sau-geografic",
        prefix: "ath",
        ctg: "nume-teritorial-sau-geografic"
      },
      {
        text: true,
        title: "Subiect",
        to: "/ath/cat-subiect",
        prefix: "ath",
        ctg: "subiect"
      },
      {
        text: true,
        title: "Editura",
        to: "/ath/cat-nume-editura",
        prefix: "ath",
        ctg: "nume-editura"
      },
      {
        text: true,
        title: "Titlu unif.",
        to: "/ath/cat-titlu-uniform",
        prefix: "ath",
        ctg: "titlu-uniform"
      },
      {
        text: true,
        title: "Nume de familie",
        to: "/ath/cat-nume-de-familie",
        prefix: "ath",
        ctg: "nume-de-familie"
      },
      {
        text: true,
        title: "Locaţie",
        to: "/ath/cat-locatie",
        prefix: "ath",
        ctg: "locatie"
      },
      {
        text: true,
        title: "Tezaur",
        to: "/ath/cat-tezaur",
        prefix: "ath",
        ctg: "tezaur"
      },
      {
        text: true,
        title: "Tipăritor/Publicant",
        to: "/ath/cat-tiparitor-publicant",
        prefix: "ath",
        ctg: "tiparitor-publicant"
      },
      {
        text: true,
        title: "Editura",
        to: "/ath/cat-nume-editura",
        prefix: "ath",
        ctg: "nume-editura"
      },
      {
        icon: "mdi-card-account-details-outline",
        title: "Catalogul autorilor",
        to: "/ath/cat-nume-personal"
      },
      {
        icon: "mdi-account-group",
        title: "Cărți pentru copii",
        to: "/cop"
      }
    ]
  }),
  computed: {
    ...mapState(["barColor", "barImage"]),
    appType() {
      return this.$store.getters.appType
    },
    biblioteca() {
      return this.$store.getters.libraryData.library.public_name
    },
    libraryCode() {
      return this.$store.getters.libraryData.library.code
    },
    accountEmail() {
      return this.$store.getters.userEmail
    },
    menuItems() {
      //this.$log("bar color ", this.barColor)
      const menu = []
      const path = this.$route.path
      //this.$log("menu path ", path)
      if (path.indexOf("/mon") >= 0) {
        menu.push(...this.items.filter(itm => !itm.ctg || (itm.prefix == "mon" && itm.count > 0)))
      } else if (path.indexOf("/ath") >= 0) {
        menu.push(...this.items.filter(itm => !itm.ctg || (itm.prefix == "ath" && itm.count > 0)))
      } else if (path.indexOf("/art") >= 0) {
        menu.push(...this.items.filter(itm => !itm.ctg || (itm.prefix == "art" && itm.count > 0)))
      } else {
        menu.push(...this.items.filter(itm => !itm.ctg))
      }
      //this.$log("menuuu ", menu)
      return menu
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val)
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val)
    }
  },
  created() {
    this.$log("drawer created ", this.accountEmail)
    if (this.accountEmail) {
      axios
        .get('ebib_marc_cln?where={"fields.201.n.val":"' + encodeURI(this.accountEmail) + '"}&max_results=1&page=1')
        .then(res => {
          //this.$log("res ", res)
          if (res.status == 200) {
            if (res.data._items.length == 1) {
              this.$store.commit("userId", res.data._items[0]._id)
              //this.$store.commit("userFavorites", res.data._items[0].favorites)
              this.$store.commit("userBarcode", res.data._items[0].fields["500"][0].a[0].val)
            } else {
              this.$store.commit("userId", "")
              this.$store.commit("userBarcode", "")
            }
          }
        })
    }
    this.reloadData()
  },
  methods: {
    reloadData() {
      this.getCount("mon")
      this.getCount("ath")
      this.getCount("art")
    },
    getCount(prefix) {
      axios.get("marc/count_categories/" + prefix).then(res => {
        let total = 0
        for (const cat in res.data.data) {
          total += res.data.data[cat]
          if (this.items.find(itm => itm.prefix == prefix && itm.ctg == cat)) {
            this.$set(
              this.items.find(itm => itm.prefix == prefix && itm.ctg == cat),
              "count",
              String(res.data.data[cat])
            )
          }
        }
        this.$set(
          this.items.find(itm => itm.prefix == prefix && itm.ctg == ""),
          "count",
          String(total)
        )
      })
    }
  }
}
</script>
